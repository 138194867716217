import React from "react";
import theme from "theme";
import { Theme, Text, Strong, List, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar />
		<Section margin="100px 0 0 0">
			{"  "}
			<Text as="h1" font="--headline1">
				Privacy Policy
			</Text>
			{"  "}
			<Text font="--base">
				This Privacy Policy applies between you, the User of this Website, and Wavedropper LTD, the owner and provider of this Website. Wavedropper LTD takes the privacy of your information very seriously. This Privacy Policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website.
			</Text>
			{"  "}
			<Text font="--base">
				This Privacy Policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: https://wavedropper.com/terms.
			</Text>
			{"  "}
			<Text font="--base">
				Please read this Privacy Policy carefully.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Definitions and Interpretation
			</Text>
			{"  "}
			<Text font="--base">
				In this Privacy Policy, the following definitions are used:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					<Strong>
						Data:
					</Strong>
					{" "}collectively all information that you submit to Wavedropper LTD via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws;
				</Text>
				<Text font="--base">
					<Strong>
						Cookies:
					</Strong>
					{" "}a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below (Cookies);
				</Text>
				<Text font="--base">
					<Strong>
						Data Protection Laws:
					</Strong>
					{" "}any applicable law relating to the processing of personal Data, including but not limited to the GDPR, and any national implementing and supplementary laws, regulations and secondary legislation;
				</Text>
				<Text font="--base">
					<Strong>
						GDPR:
					</Strong>
					{" "}the UK General Data Protection Regulation;
				</Text>
				<Text font="--base">
					<Strong>
						Wavedropper LTD, we or us:
					</Strong>
					{" "}Wavedropper LTD, a company incorporated in England and Wales with registered number 15491549 whose registered office is at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ;
				</Text>
				<Text font="--base">
					<Strong>
						UK and EU Cookie Law:
					</Strong>
					{" "}the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011 & the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2018;
				</Text>
				<Text font="--base">
					<Strong>
						User or you:
					</Strong>
					{" "}any third party that accesses the Website and is not either (i) employed by Wavedropper LTD and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Wavedropper LTD and accessing the Website in connection with the provision of such services; and
				</Text>
				<Text font="--base">
					<Strong>
						Website:
					</Strong>
					{" "}the website that you are currently using, https://wavedropper.com, and any sub-domains of this site unless expressly excluded by their own terms and conditions.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				In this Privacy Policy, unless the context requires a different interpretation:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					the singular includes the plural and vice versa;
				</Text>
				<Text font="--base">
					references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this Privacy Policy;
				</Text>
				<Text font="--base">
					a reference to a person includes firms, companies, government entities, trusts and partnerships;
				</Text>
				<Text font="--base">
					"including" is understood to mean "including without limitation";
				</Text>
				<Text font="--base">
					reference to any statutory provision includes any modification or amendment of it;
				</Text>
				<Text font="--base">
					the headings and sub-headings do not form part of this Privacy Policy.
				</Text>
			</List>
			{"  "}
			<Text as="h2" font="--headline2">
				Scope of this Privacy Policy
			</Text>
			{"  "}
			<Text font="--base">
				This Privacy Policy applies only to the actions of Wavedropper LTD and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.
			</Text>
			{"  "}
			<Text font="--base">
				For purposes of the applicable Data Protection Laws, Wavedropper LTD is the "data controller". This means that Wavedropper LTD determines the purposes for which, and the manner in which, your Data is processed.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Data Collected
			</Text>
			{"  "}
			<Text font="--base">
				We may collect the following Data, which includes personal Data, from you:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					name;
				</Text>
				<Text font="--base">
					gender;
				</Text>
				<Text font="--base">
					profession;
				</Text>
				<Text font="--base">
					contact Information such as email addresses and telephone numbers;
				</Text>
				<Text font="--base">
					demographic information such as postcode, preferences and interests;
				</Text>
				<Text font="--base">
					IP address (automatically collected);
				</Text>
				<Text font="--base">
					web browser type and version (automatically collected);
				</Text>
				<Text font="--base">
					operating system (automatically collected);
				</Text>
				<Text font="--base">
					in each case, in accordance with this Privacy Policy.
				</Text>
			</List>
			{"  "}
			<Text as="h2" font="--headline2">
				How We Collect Data
			</Text>
			{"  "}
			<Text font="--base">
				We collect Data in the following ways:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					data is given to us by you;
				</Text>
				<Text font="--base">
					data is received from other sources; and
				</Text>
				<Text font="--base">
					data is collected automatically.
				</Text>
			</List>
			{"  "}
			<Text as="h3" font="--headline3">
				Data That is Given to Us by You
			</Text>
			{"  "}
			<Text font="--base">
				Wavedropper LTD will collect your Data in a number of ways, for example:
			</Text>
			{"  "}
			<List font="--base">
				{"    "}
				<Text font="--base">
					when you contact us through the Website, by telephone, post, e-mail or through any other means;
				</Text>
				<Text font="--base">
					when you register with us and set up an account to receive our products/services;
				</Text>
				<Text font="--base">
					when you make payments to us, through this Website or otherwise;
				</Text>
				<Text font="--base">
					when you use our services;
				</Text>
				<Text font="--base">
					in each case, in accordance with this Privacy Policy.
				</Text>
			</List>
			{"  "}
			<Text as="h3" font="--headline3">
				Data That is Received From Third Parties
			</Text>
			{"  "}
			<Text font="--base">
				Wavedropper LTD will receive Data about you from the following third parties:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					Google.
				</Text>
			</List>
			{"  "}
			<Text as="h3" font="--headline3">
				Data That is Collected Automatically
			</Text>
			{"  "}
			<Text font="--base">
				To the extent that you access the Website, we will collect your Data automatically, for example:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					we automatically collect some information about your visit to the Website. This information helps us to make improvements to Website content and navigation, and includes your IP address, the date, times and frequency with which you access the Website and the way you use and interact with its content.
				</Text>
				<Text font="--base">
					we will collect your Data automatically via cookies, in line with the cookie settings on your browser. For more information about cookies, and how we use them on the Website, see the section below, headed "Cookies".
				</Text>
			</List>
			{"  "}
			<Text as="h2" font="--headline2">
				Our Use of Data
			</Text>
			{"  "}
			<Text font="--base">
				Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data may be used by us for the following reasons:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					internal record keeping;
				</Text>
				<Text font="--base">
					improvement of our products / services;
				</Text>
				<Text font="--base">
					contact for market research purposes which may be done using email, telephone, fax or mail. Such information may be used to customise or update the Website;
				</Text>
				<Text font="--base">
					in each case, in accordance with this Privacy Policy.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances (see the section headed "Your rights" below).
			</Text>
			{"  "}
			<Text font="--base">
				When you register with us and set up an account to receive our services, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.
			</Text>
			{"  "}
			<Text font="--base">
				We may use your Data to show you Wavedropper LTD adverts and other content on other websites. If you do not want us to use your data to show you Wavedropper LTD adverts and other content on other websites, please turn off the relevant cookies (please refer to the section headed "Cookies" below).
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Who We Share Data With
			</Text>
			{"  "}
			<Text font="--base">
				We may share your Data with the following groups of people for the following reasons:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					our employees, agents and/or professional advisors - to obtain advice from professional advisers, to improve our services, and to support our business operations;
				</Text>
				<Text font="--base">
					third party service providers who provide services to us which require the processing of personal data - to help third party service providers in receipt of any shared data to perform functions on our behalf to help ensure the website runs smoothly;
				</Text>
				<Text font="--base">
					third party payment providers who process payments made over the Website - to enable third party payment providers to process user payments and refunds;
				</Text>
				<Text font="--base">
					in each case, in accordance with this Privacy Policy.
				</Text>
			</List>
			{"  "}
			<Text as="h2" font="--headline2">
				Keeping Data Secure
			</Text>
			{"  "}
			<Text font="--base">
				We will use technical and organisational measures to safeguard your Data, for example:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					access to your account is controlled by a password and a user name that is unique to you.
				</Text>
				<Text font="--base">
					we store your Data on secure servers.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this e-mail address: admin@wavedropper.com.
			</Text>
			{"  "}
			<Text font="--base">
				If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Data Retention
			</Text>
			{"  "}
			<Text font="--base">
				Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy or until you request that the Data be deleted.
			</Text>
			{"  "}
			<Text font="--base">
				Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Your Rights
			</Text>
			{"  "}
			<Text font="--base">
				You have the following rights in relation to your Data:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					<Strong>
						Right to access
					</Strong>
					{" "}- the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why.
				</Text>
				<Text font="--base">
					<Strong>
						Right to correct
					</Strong>
					{" "}- the right to have your Data rectified if it is inaccurate or incomplete.
				</Text>
				<Text font="--base">
					<Strong>
						Right to erase
					</Strong>
					{" "}- the right to request that we delete or remove your Data from our systems.
				</Text>
				<Text font="--base">
					<Strong>
						Right to restrict our use of your Data
					</Strong>
					{" "}- the right to "block" us from using your Data or limit the way in which we can use it.
				</Text>
				<Text font="--base">
					<Strong>
						Right to data portability
					</Strong>
					{" "}- the right to request that we move, copy or transfer your Data.
				</Text>
				<Text font="--base">
					<Strong>
						Right to object
					</Strong>
					{" "}- the right to object to our use of your Data including where we use it for our legitimate interests.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail address: admin@wavedropper.com.
			</Text>
			{"  "}
			<Text font="--base">
				If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact details can be found on their website at https://ico.org.uk/.
			</Text>
			{"  "}
			<Text font="--base">
				It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period for which we hold it.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Links to Other Websites
			</Text>
			{"  "}
			<Text font="--base">
				This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This Privacy Policy does not extend to your use of such websites. You are advised to read the Privacy Policy or statement of other websites prior to using them.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Changes of Business Ownership and Control
			</Text>
			{"  "}
			<Text font="--base">
				Wavedropper LTD may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of Wavedropper LTD. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the Data for the purposes for which it was originally supplied to us.
			</Text>
			{"  "}
			<Text font="--base">
				We may also disclose Data to a prospective purchaser of our business or any part of it.
			</Text>
			{"  "}
			<Text font="--base">
				In the above instances, we will take steps with the aim of ensuring your privacy is protected.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Cookies
			</Text>
			{"  "}
			<Text font="--base">
				This Website may place and access certain Cookies on your computer. Wavedropper LTD uses Cookies to improve your experience of using the Website and to improve our range of services. Wavedropper LTD has carefully chosen these Cookies and has taken steps to ensure that your privacy is protected and respected at all times.
			</Text>
			{"  "}
			<Text font="--base">
				All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.
			</Text>
			{"  "}
			<Text font="--base">
				Before the Website places Cookies on your computer, you will be presented with a message bar requesting your consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling Wavedropper LTD to provide a better experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of the Website may not function fully or as intended.
			</Text>
			{"  "}
			<Text font="--base">
				This Website may place the following Cookies:
			</Text>
			{"  "}
			<List font="--base">
				<Text font="--base">
					<Strong>
						Type of Cookie
					</Strong>
				</Text>
				<Text font="--base">
					<Strong>
						Purpose
					</Strong>
				</Text>
				<Text font="--base">
					Strictly necessary cookies - These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
				</Text>
				<Text font="--base">
					Analytical/performance cookies - They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
				</Text>
				<Text font="--base">
					Functionality cookies - These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region). By using the Website, you agree to our placement of functionality cookie.
				</Text>
				<Text font="--base">
					Targeting cookies - These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
				</Text>
			</List>
			{"  "}
			<Text font="--base">
				You can find a list of Cookies that we use in the Cookies Schedule.
			</Text>
			{"  "}
			<Text font="--base">
				We give you control over which Cookies we use. You can adjust your cookies preferences at any time at: __________.
			</Text>
			{"  "}
			<Text font="--base">
				You can also choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but this can be changed. For further details, please see the help menu in your internet browser. You can switch off Cookies at any time, however, you may lose any information that enables you to access the Website more quickly and efficiently.
			</Text>
			{"  "}
			<Text font="--base">
				You can choose to delete Cookies at any time; however, you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.
			</Text>
			{"  "}
			<Text font="--base">
				It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.
			</Text>
			{"  "}
			<Text font="--base">
				For more information generally on cookies, including how to disable them, please refer to aboutcookies.org. You will also find details on how to delete cookies from your computer.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				General
			</Text>
			{"  "}
			<Text font="--base">
				You may not transfer any of your rights under this Privacy Policy to any other person. We may transfer our rights under this Privacy Policy where we reasonably believe your rights will not be affected.
			</Text>
			{"  "}
			<Text font="--base">
				If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be affected.
			</Text>
			{"  "}
			<Text font="--base">
				Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
			</Text>
			{"  "}
			<Text font="--base">
				This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
			</Text>
			{"  "}
			<Text as="h2" font="--headline2">
				Changes to This Privacy Policy
			</Text>
			{"  "}
			<Text font="--base">
				Wavedropper LTD reserves the right to change this Privacy Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the Privacy Policy on your first use of the Website following the alterations. You may contact Wavedropper LTD by email at admin@wavedropper.com.
			</Text>
			{"  "}
			<Text font="--base">
				This Privacy Policy was created on 03 July 2024.
			</Text>
		</Section>
		<Section>
			<Link href="/cookies" color="#000000">
				More about cookies we use
			</Link>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});